<template>
    <div>
        <el-button class="head-btn" type="primary" @click="addClickBtn">添加</el-button>
            
            <el-table :data="tableData" style="width: 100%" border>

                <el-table-column
                    prop="name"
                    label="名字"
                    width="130">
                </el-table-column>
                
                <el-table-column
                    prop="realPrice"
                    label="现价（元）"
                    width="130">
                </el-table-column>
                
                <el-table-column
                    prop="originalPrice"
                    label="原价（元）"
                    width="130">
                </el-table-column>

                <!-- <el-table-column
                    prop="type"
                    label="类型">
                    <template slot-scope="scope">
                        <div v-if="scope.row.type == 1" >免费版</div>
                        <div v-if="scope.row.type == 2" >企业标准版</div>
                        <div v-if="scope.row.type == 3" >企业旗舰版</div>
                    </template>
                </el-table-column> -->

                <el-table-column
                    prop="status"
                    label="状态">
                    <template slot-scope="scope">

                        <div v-if="scope.row.status == 1" class="yes">启动</div>
                        <div v-if="scope.row.status == 2" class="no">禁用</div>

                    </template>
                </el-table-column>

                <el-table-column
                    prop="years"
                    label="年数">
                </el-table-column>

                <el-table-column
                    prop="days"
                    label="天数">
                </el-table-column>

                <el-table-column
                    prop="sort"
                    label="排序号">
                </el-table-column>

                <el-table-column
                    prop="type"
                    label="类型">
                    <template slot-scope="scope">
                        <!-- 0 不设置，1 体验或免费版，2 标准版，3 高级版 -->
                        <div v-if="scope.row.type == 0" >不设置</div>
                        <div v-if="scope.row.type == 1" >体验或免费版</div>
                        <div v-if="scope.row.type == 2" >标准版</div>
                        <div v-if="scope.row.type == 3" >高级版</div>

                    </template>
                </el-table-column>

                <el-table-column
                    prop="walletBalance"
                    label="算力">
                    <template slot-scope="scope">
                        {{ scope.row.walletBalance }}
                    </template>
                    
                </el-table-column>

                <el-table-column
                    prop="models"
                    label="功能">
                    <template slot-scope="scope">
                        
                        {{ getModelNameList(scope.row.models) }}

                    </template>
                </el-table-column>

                <el-table-column
                    fixed="right"
                    label="操作"
                    width="220">
                    <template slot-scope="scope">

                        <el-button @click.stop="editBtn(scope.row)" type="text" size="small">
                            编辑
                        </el-button>

                    </template>
                </el-table-column>
            
            </el-table>

            <el-pagination
                background
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="pageNumber"
                :page-sizes="[20, 50, 100, 150]"
                :page-size="pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total">
            </el-pagination>

            <AddOrgPruductPay :visible.sync="AddMoneyPayVisible" @ok="getData" ref="AddMoneyPayRef"></AddOrgPruductPay>

    </div>
</template>
  
<script>

    import AddOrgPruductPay from "./AddOrgPruductPay2.vue"
    import Big from 'big.js';

  export default {
    components: { AddOrgPruductPay},
    data() {
      return {
        AddMoneyPayVisible:false,
        tableData:[],
        total:0,
        pageNumber:1,
        pageSize:40,
        queryParams:{},
        modelLibrary:[]
      };
    },
    async mounted() {

        this.$https('getModels', {}).then(res => {

            this.modelLibrary = res.data

            this.getData()
        })

    },
    methods: {
        getData() {
            
            this.$https('queryGoods', {
                pageNumber: this.pageNumber,
                pageSize: this.pageSize,
                goodsType:5,
                ...this.queryParams
            }).then(res => {

                res.data.records.forEach((val)=>{
                    val.goodsOrgMemberVip.originalPrice = parseFloat(new Big(val.goodsOrgMemberVip.originalPrice).div(100) )
                    val.goodsOrgMemberVip.realPrice = parseFloat(new Big(val.goodsOrgMemberVip.realPrice).div(100))
                    Object.assign(val, val.goodsOrgMemberVip)
                })
                this.tableData = res.data.records
                
                this.total = parseInt(res.data.total)
            })
        },
        addClickBtn(){
            this.AddMoneyPayVisible = true
            this.$nextTick(()=>{
                this.$refs.AddMoneyPayRef.start()
            })
        },
        handleSizeChange(size) {
            this.pageSize = size
            this.getData()
        },
        handleCurrentChange(num) {
            this.pageNumber = num
            this.getData()
        },
        editBtn(item){
            this.AddMoneyPayVisible = true
            this.$nextTick(()=>{
                item.goodsOrgMemberVip.status = item.status
                item.goodsOrgMemberVip.sort = item.sort
                item.goodsOrgMemberVip.memo = item.memo

                this.$refs.AddMoneyPayRef.start(item.goodsOrgMemberVip, item.id)
            })

        },

        getModelNameList(models){

            var arr = []

            for(let key in models){
                var item = models[key]
                var res = this.modelLibrary.find((val)=>{
                    return val.modelValue == item.modelValue
                })
                if(res){
                    arr.push(res.name)
                }
            }

            return "【" + arr.join("】, 【") + "】"
            
        }

    },
  };
</script>
  
<style scoped >
.dialogWrap{
    padding: 20px 0 20px 80px;
    box-sizing: border-box;
    
}
.radioWrap{
    padding: 10px 0;
}
.yes{
    color: rgb(75, 167, 0);
}
.no{
    color: rgb(255, 79, 79);
}
.head-btn {
    margin-bottom: 10px;
}
</style>
  
import { render, staticRenderFns } from "./OrgPruductManager2.vue?vue&type=template&id=301e4512&scoped=true"
import script from "./OrgPruductManager2.vue?vue&type=script&lang=js"
export * from "./OrgPruductManager2.vue?vue&type=script&lang=js"
import style0 from "./OrgPruductManager2.vue?vue&type=style&index=0&id=301e4512&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "301e4512",
  null
  
)

export default component.exports